<template>
  <div>
    <b-tabs>
      <b-tab
        v-for="tab in settingTabs"
        :key="tab.id"
        :title="tab.name"
        @click="onChange(tab.id)"
      >
        <b-overlay :show="loading">
          <good-table-selectable
            :items="contracts.data"
            :columns="columns"
            :total="contracts.total"
            :page="page"
            :has-show="true"
            :status="filterModel.images_confirmation_status"
            model="ConfirmContractPhotos"
            :filter="filterModel"
            @getItems="getItems"
            @selectedRows="selectedRows"
            @search="searchFilter"
            @confirm="confirmAction"
            @onPageChange="(p) => page = p"
          />
        </b-overlay>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { showToast } from '@/utils/toast'
import GoodTableSelectable from '@/views/table/vue-good-table/GoodTableColumnSearch.vue'

export default {
  name: 'Index',
  components: {
    GoodTableSelectable,
  },
  data() {
    return {
      page: 1,
      loading: false,
      filterModel: {
        per_page: 50,
        number: null,
        search: null,
        images_confirmation_status: 0,
        relations: 'client',
      },
      settingTabs: [
        {
          id: 0,
          name: this.$t('Для подтверждения'),
        },
        {
          id: 1,
          name: this.$t('Одобренные'),
        },
        {
          id: 2,
          name: this.$t('Отказанные'),
        },
      ],
      payment_ids: [],
    }
  },
  computed: {
    ...mapGetters({
      contracts: 'contract/GET_ITEMS',
    }),
    columns() {
      return [
        {
          label: '#',
          field: 'row_number',
        },
        {
          label: this.$t('Фамилия'),
          field: 'client.lastname',
          showField: true,
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: this.$t('Имя'),
          field: 'client.firstname',
          showField: true,
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: this.$t('Отчества'),
          field: 'client.middlename',
          showField: true,
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: this.$t('Телефон'),
          field: 'client.phone',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        // {
        //   label: this.$t('Паспорт'),
        //   field: this.passportField,
        //   filterField: 'passport',
        //   filterOptions: {
        //     enabled: true,
        //     placeholder: '',
        //   },
        // },
        {
          label: this.$t('Сумма'),
          field: 'amount',
          isAmount: true,
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: this.$t('Действия'),
          field: this.hasPermission('contracts.update-images-confirmation-status') ? 'confirm_action' : '',
          isOrders: true,
        },
      ]
    },
  },
  watch: {
    page(newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
    'filterModel.per_page': function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
  },
  mounted() {
    this.getItems()
  },
  methods: {
    ...mapActions({
      getItemsAction: 'contract/index',
      updateImagesConfirmation: 'contract/updateImagesConfirmation',
      destroyAction: 'region/destroy',
      getCitiesAction: 'city/index',
      destroyCityAction: 'city/destroy',
    }),
    onChange(id) {
      this.filterModel.images_confirmation_status = id
      this.getItems()
    },
    passportField(row) {
      return row.client.doc_series + row.client.doc_number
    },
    selectedRows(rows) {
      this.payment_ids = []
      if (rows && rows.length) {
        rows.forEach(el => {
          this.payment_ids.push(el.id)
        })
      }
    },
    confirmAction(id) {
      this.$bvModal.msgBoxConfirm(this.$t('Shartnomani tasdiqlaysizmi?'), { centered: true })
        .then(value => {
          if (value) {
            this.loading = true
            this.updateImagesConfirmation({ images_confirmation_status: 1, id })
              .then(() => {
                this.getItems()
                this.payment_ids = []
                showToast('success', this.$t('Успешно подтверждено'))
              })
              .finally(() => {
                this.loading = true
              })
          }
        })
    },
    searchFilter(search) {
      this.filterModel.search = search
      this.getItems()
    },
    async getItems() {
      this.loading = true
      await this.getItemsAction({ ...this.filterModel, page: this.page })
      this.loading = false
    },
  },
}
</script>

<style scoped>

</style>
